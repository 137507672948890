import React, { useEffect, useState } from 'react';
import server from '../../api/server';
import useGlobal from '../../store';
import { workstations, partNumbers, activeManufacturing, routings, workerGroupAiChat } from '../dbNames';
import { EMPTY } from '../statusTypes';
import { string } from 'prop-types';
import '../style.css'
import moment from 'moment';

const ActivatePO = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        workstation: undefined, poNum: undefined, partSerialNum: undefined, partNumList: [],
        aiWorkerPrompt: '',
        status: 'Enter PO Number, part serial number and select part number to activate',
        input: { password: '' }
    });

    useEffect(() => {
        globalActions.getData(routings, '{}', globalState.token);
        globalActions.configureAction('configBuffer', undefined)
    }, []);

    function activate(event) {
        if (event !== undefined) {  
            if (state.poNum === undefined || state.partSerialNum === undefined || state.workstation === undefined) {
                setState({ ...state, status: 'YOU NEED TO ENTER PO NUMBER, PART SERIAL NUMBER AND SELECT A WORKSTATION ENTRY' })
            } else if (globalState.settings.stellarNetwork !== 'NONE' & state.input.password === '') {
                setState({ ...state, status: 'YOU NEED TO ENTER YOUR PASSWORD TO ACTIVATE THE PO' })
            } else {
                setState({ ...state, status: 'Activating....' })

                var partNumberName = '';
                for (let i = 0; i < globalState.partNumbers.length; i++) {
                    if (globalState.partNumbers[i]._id === event.target.id) {
                        partNumberName = globalState.partNumbers[i].name;
                    }
                }
                const config = {
                    headers: { Authorization: `Bearer ${globalState.token}` }
                };
                server.post(`/activatePO`, { workstationId: state.workstation, poNum: state.poNum, partSerialNum: state.partSerialNum, partNumberName, password: state.input.password, aiWorkerPrompt: state.aiWorkerPrompt }, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'PO successfully activated' });
                            globalActions.getData(activeManufacturing, '{}', globalState.token);
                            globalActions.getData(workstations, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `Their was an error in the response, check if PO created` })
                        }

                    }).catch((error) => {
                        console.log(error)
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = ''
                        
                        switch (errorCode) {
                            case 411:
                                message = 'Error 411: The Command-Central account needs more funding.  Please contact Command Robotics.'
                                break;
                            case 410:
                                message = 'Error 410: More funds required in your account'
                                break;
                            case 409:
                                message = 'Error 409: PO or Serial Number already exists'
                                break;
                            case 408:
                                message = 'Error 408: Error with Password'
                                break;
                            case 407:
                                message = 'Error 407: Error creating routing account'
                                break;
                            case 406:
                                message = 'Error 406: Routing account created but not properly funded'
                                break;
                            case 405:
                                message = 'Error 405: Error processing the payment to the network owner'
                                break;
                            case 404:
                                message = 'Error 404: Error processing the payment to the routingr'
                                break;
                            case 403:
                                message = 'Error 403: No room in the Buffer'
                                break;
                            case 402:
                                message = 'Error 402: Buffer status is not WAITING'
                                break;
                            case 400:
                                message = 'Error 400: Problems activating routing'
                                break;
                            default:
                                message = 'Unknown error'
                                break;
                        }

                        setState({ ...state, status: message });
                    });
            }
        };
    }

    function renderStatus() {
        if (globalState.partNumbers === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    function renderForm() {
        if (globalState.configBuffer !== undefined) {
            return (
                <form>
                    <div>
                        Enter a prompt for the AI workers:
                        <input
                                className="input chat"
                                placeholder='Enter your message....'
                                type={string}
                                name='aiWorkerPrompt'
                                value={state.aiWorkerPrompt}
                                onChange={e => {
                                    var aiWorkerPrompt = state.aiWorkerPrompt
                                    aiWorkerPrompt = e.target.value
                                    setState({ ...state, aiWorkerPrompt: aiWorkerPrompt })
                                }}
                                autoComplete="off"
                        />
                    </div>
                    <div>
                        Enter a unique PO identifier:
                        <input
                            className="input spacing"
                            type="text"
                            value={state.poNum}
                            onChange={(e) => setState({ ...state, poNum: e.target.value })}
                        />
                    </div>
                    <div>
                        Enter a unique Serial identifier:
                        <input
                            className="input spacing"
                            type="text"
                            value={state.partSerialNum}
                            onChange={(e) => setState({ ...state, partSerialNum: e.target.value })}
                        />
                    </div>
                    <div className="ui middle aligned divided list">
                        {globalState.partNumbers.map(partNumList => (
                        <div className="item" key={`${partNumList._id}`}>
                            <div className="right floated content">
                                <div className="button" id={partNumList._id} onClick={activate}>Activate</div>
                            </div>
                            {renderPassword()}
                            <div className="content">
                                <div className="header">Customer: {partNumList.name}</div>
                            </div>
                            <div className="content">
                                Routing ID: {partNumList.routingID}
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="chat-container">
                        {globalState.activeManufacturing.map(active => {
                            return(
                            active.routing.steps.map(step => {
                                if(step.workerGroup.includes(workerGroupAiChat)) {
                                    if(step.kpv.textAssetGenerated === 'NONE') {
                                        return (
                                            <div div className="message" key={step._id}>
                                                <span className="message-text">Compiling your answer...hold tight!</span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                        <div div className="message" key={step._id}>
                                            <span className="message-text">{step.kpv.textAssetGenerated}</span>
                                        </div>
                                    )
                                    }
                                }
                            }))
                        })}
                    </div>
                    
                </form>
            )
        } else {
            return (
                <div>
                    <h2>Select an available infeed buffer to introduce part</h2>
                </div>
            )
        }

    }

    function selectBuffer(event) {
        if (event !== undefined) {
            for (var i = 0; i < globalState.workstations.length; i++) {
                var ws = undefined;
                var status = '';
                if (globalState.workstations[i]._id === event.target.id) {
                    if (globalState.workstations[i].poInStation[0] === EMPTY) {
                        ws = event.target.id;
                        status = 'Enter PO Number, part serial number and select part number to activate'
                    } else {
                        status = 'PLEASE SELECT AN EMPTY PLACE'
                    }
                    globalActions.configureAction('configBuffer', ws)
                    setState({ ...state, status: status, workstation: ws })
                    break;
                }
            }
        }
    }

    function renderPassword() {
        if (globalState.settings === undefined) {
            return (<div className="right floated content">Loading...</div>)
        } else {
            if (globalState.settings.stellarNetwork === 'NONE') {
                return (<div className="right floated content">Password not required</div>)
            } else {
                return (
                    <div className="right floated content">
                        Password:
                        <input
                            className="input"
                            type="password"
                            name='password'
                            value={state.input.password}
                            onChange={e => {
                                var user = state.input
                                user.password = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                )
            }
        }
    }

    function renderPartNumList() {
        if (globalState.partNumbers === undefined || globalState.workstations === undefined) {
            globalActions.getData(workstations, '{}', globalState.token);
            globalActions.getData(partNumbers, '{}', globalState.token);
            return (<div>Loading...</div>)
        } else {
            return (
                <div>
                    <div className="ui message">
                        <h2>Activate a PO</h2>
                    </div>
                    <div className="grid">
                        <div className="quality1">
                            <div className="spacing">Infeed stations:</div>
                            {globalState.workstations.map(wk => {
                                var selected = "poContent"
                                if (wk._id === globalState.configBuffer) {
                                    selected = "poContent selected";
                                }
                                if (wk.workstationGroup.includes("Infeed Buffer")) {
                                    return (
                                        <div className={selected} id={wk._id} onClick={selectBuffer} >
                                            <div className="header" id={wk._id}>
                                                PO in station: {wk.poInStation}
                                            </div>
                                            <div className="header" id={wk._id}>
                                                Name: {wk.name}
                                            </div>
                                            <div className="header" id={wk._id}>
                                                Status: {wk.status}
                                            </div>
                                        </div>
                                    )
                                } else { return <div></div> }
                            })}
                        </div>
                            <div className="editWindow">
                                {renderForm()}
                                
                            </div>
                    </div>



                    <div className="ui message">
                        <div className="header">
                            Status:
                        </div>
                        {renderStatus()}
                    </div>
                </div>
            );
        }
    }
    return (
        <div>
            {renderPartNumList()}
        </div>
    );
};

export default ActivatePO;