import React, { useEffect, useState } from 'react';
import '../style.css';
import { routings } from '../dbNames';
import useGlobal from '../../store';
import server from '../../api/server';

const RoutingConfig = () => {
    const allowedStepUpdates = ['name', 'workstationGroup', 'workerGroup', 'tools', 'program', 'assignedCycleTimeSeconds', 'assignedDeliveryTimeSeconds', 'documentation'];
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure routings',
        input: undefined
    });

    useEffect(() => {
        globalActions.getData(routings, '{}', globalState.token);
        globalActions.configureAction('configRouting', undefined);
    }, []);

    useEffect(() => {
        if (globalState.configRouting !== 'new' && globalState.configRouting !== undefined) {
            for (var i = 0; i < globalState.routings.length; i++) {
                if (globalState.routings[i]._id === globalState.configRouting) {
                    // Convert params objects to arrays if coming from server
                    const routing = {
                        ...globalState.routings[i],
                        steps: globalState.routings[i].steps.map(step => ({
                            ...step,
                            tools: step.tools.map(tool => ({
                                ...tool,
                                params: Object.entries(tool.params).map(([key, value]) => ({ key, value }))
                            })),
                            program: step.program.map(program => ({
                                ...program,
                                params: Object.entries(program.params).map(([key, value]) => ({ key, value }))
                            }))
                        }))
                    };
                    setState({ ...state, input: routing });
                    break;
                }
            }
        }
    }, [globalState.configRouting]);

    const selectRouting = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configRouting', event.target.id);
            setState({ ...state, status: 'Ready to configure routings' });
        }
    };

    const newRouting = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configRouting', 'new');
            const newRouting = {
                name: '**name**',
                steps: [
                    {
                        name: '**step name**',
                        workstationGroup: 'Workstation Group',
                        assignedCycleTimeSeconds: 9999,
                        assignedDeliveryTimeSeconds: 9999,
                        workerGroup: ['PO Movement'],
                        tools: [
                            { type: 'tool1', params: [{ key: 't', value: 1 }, { key: 'assets', value: 'ALL' }] }
                        ],
                        program: [
                            { type: 'assetSearch', params: [{ key: 'k', value: 3 }, { key: 'assets', value: 'ALL' }] },
                            { type: 'ai', params: [{ key: 'model', value: 'gpt-4o-mini' }, { key: 'temperature', value: 1 }, { key: 'max_completion_tokens', value: 2000 }] }
                        ],
                        documentation: 'Notes'
                    },
                    {
                        name: 'Outfeed Buffer',
                        workstationGroup: 'Outfeed Buffer',
                        assignedCycleTimeSeconds: 0,
                        assignedDeliveryTimeSeconds: 0,
                        workerGroup: [],
                        tools: [
                            { type: 'tool1', params: [{ key: 't', value: 1 }, { key: 'assets', value: 'ALL' }] }
                        ],
                        program: [
                            { type: 'assetSearch', params: [{ key: 'k', value: 3 }, { key: 'assets', value: 'ALL' }] },
                            { type: 'ai', params: [{ key: 'model', value: 'gpt-4o-mini' }, { key: 'temperature', value: 1 }, { key: 'max_completion_tokens', value: 2000 }] }
                        ],
                        documentation: ''
                    }
                ]
            };
            setState({ ...state, input: newRouting });
        }
    };

    const insertStep = (event) => {
        if (event !== undefined) {
            var routing = { ...state.input };
            var newStep = {
                name: 'Step_number',
                workstationGroup: 'Workstation Group',
                assignedCycleTimeSeconds: 9999,
                assignedDeliveryTimeSeconds: 9999,
                workerGroup: ['PO Movement'],
                tools: [
                    { type: 'tool1', params: [{ key: 't', value: 1 }, { key: 'assets', value: 'ALL' }] }
                ],
                program: [
                    { type: 'assetSearch', params: [{ key: 'k', value: 3 }, { key: 'assets', value: 'ALL' }] }
                ],
                documentation: 'Notes'
            };
            routing.steps.splice(-1, 0, newStep);
            setState({ ...state, input: routing });
        }
    };

    const addArrayItem = (stepIndex, field) => {
        var routing = { ...state.input };
        if (field === 'workerGroup') {
            routing.steps[stepIndex][field].push('New Worker');
        } else {
            const newItem = field === 'tools'
                ? { type: 'tool1', params: [{ key: 't', value: 1 }, { key: 'assets', value: 'ALL' }] }
                : { type: 'assetSearch', params: [{ key: 'k', value: 3 }, { key: 'assets', value: 'ALL' }] };
            routing.steps[stepIndex][field].push(newItem);
        }
        setState({ ...state, input: routing });
    };

    const removeArrayItem = (stepIndex, field, itemIndex) => {
        var routing = { ...state.input };
        routing.steps[stepIndex][field].splice(itemIndex, 1);
        setState({ ...state, input: routing });
    };

    const addParam = (stepIndex, field, itemIndex) => {
        var routing = { ...state.input };
        routing.steps[stepIndex][field][itemIndex].params.push({ key: 'newKey', value: 'newValue' });
        setState({ ...state, input: routing });
    };

    const removeParam = (stepIndex, field, itemIndex, paramIndex) => {
        var routing = { ...state.input };
        routing.steps[stepIndex][field][itemIndex].params.splice(paramIndex, 1);
        setState({ ...state, input: routing });
    };

    const updateParamKey = (stepIndex, field, itemIndex, paramIndex, newKey) => {
        var routing = { ...state.input };
        const params = routing.steps[stepIndex][field][itemIndex].params;
        if (newKey !== params[paramIndex].key && !params.some(p => p.key === newKey)) {
            params[paramIndex].key = newKey;
            setState({ ...state, input: routing });
        }
    };

    const updateParamValue = (stepIndex, field, itemIndex, paramIndex, newValue) => {
        var routing = { ...state.input };
        const currentValue = routing.steps[stepIndex][field][itemIndex].params[paramIndex].value;
        routing.steps[stepIndex][field][itemIndex].params[paramIndex].value =
            typeof currentValue === 'number' ? Number(newValue) : newValue;
        setState({ ...state, input: routing });
    };

    const submitChanges = (event) => {
        if (event !== undefined) {
            var updateObject = { ...state.input };
            if (event.target.id === 'clone') {
                updateObject.name += ' Copy';
            }

            // Convert params arrays back to objects for server
            updateObject.steps = updateObject.steps.map(step => ({
                ...step,
                tools: step.tools.map(tool => ({
                    ...tool,
                    params: Object.fromEntries(tool.params.map(p => [p.key, p.value]))
                })),
                program: step.program.map(program => ({
                    ...program,
                    params: Object.fromEntries(program.params.map(p => [p.key, p.value]))
                }))
            }));

            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };

            if (globalState.configRouting === 'new' || event.target.id === 'clone') {
                console.log(updateObject);
                server.post(`/routings`, updateObject, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'Routing successfully created' });
                            globalActions.getData(routings, '{}', globalState.token);
                            globalActions.configureAction('configRouting', response.data._id);
                        } else {
                            setState({ ...state, status: `There was an error in routing update, check data entered` });
                        }
                    })
                    .catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5));
                        setState({ ...state, status: `Problem saving routing error code: ${errorCode}` });
                    });
            } else {
                server.patch(`/routings/${globalState.configRouting}`, updateObject, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Routing successfully updated' });
                            globalActions.getData(routings, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `There was an error in routing update, check data entered` });
                        }
                    })
                    .catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5));
                        setState({ ...state, status: `Problem saving routing error code: ${errorCode}` });
                    });
            }
        }
    };

    const deleteRouting = (event) => {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?');
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/routings/${globalState.configRouting}`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Routing successfully deleted' });
                            globalActions.getData(routings, '{}', globalState.token);
                            globalActions.configureAction('configRouting', undefined);
                        } else {
                            setState({ ...state, status: `There was an error deleting the routing` });
                        }
                    })
                    .catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5));
                        setState({ ...state, status: `Problem deleting routing error code: ${errorCode}` });
                    });
            }
        }
    };

    function renderStatus() {
        if (globalState.routings === undefined) {
            return <p>Return to home</p>;
        } else {
            return <p>{state.status}</p>;
        }
    }

    function renderDeleteButton() {
        if (globalState.configRouting !== 'new') {
            return (
                <div>
                    <div className="button" id="save" onClick={insertStep}>
                        INSERT STEP
                    </div>
                    <div className="button" id="save" onClick={submitChanges}>
                        SAVE
                    </div>
                    <div className="button" id="clone" onClick={submitChanges}>
                        CLONE
                    </div>
                    <div className="button" id="delete" onClick={deleteRouting}>
                        DELETE
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="button" id="save" onClick={insertStep}>
                        INSERT STEP
                    </div>
                    <div className="button" id="save" onClick={submitChanges}>
                        SAVE
                    </div>
                </div>
            );
        }
    }

    function renderRoutings() {
        if (globalState.routings === undefined) {
            return <div>Loading....</div>;
        } else {
            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Routing Configuration
                            <div className="button" id="new" onClick={newRouting}>
                                NEW
                            </div>
                        </h2>
                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.routings.map((rt) => {
                                var selected = "poContent";
                                if (rt._id === globalState.configRouting) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={rt._id} onClick={selectRouting} key={rt._id}>
                                        <div className="header" id={rt._id}>
                                            <i className="industry icon" id={rt._id}></i>
                                            Name: {rt.name}
                                        </div>
                                        <div className="header" id={rt._id}>
                                            <i className="qrcode icon" id={rt._id}></i>
                                            ID: {rt._id}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            );
        }
    }

    function renderEditWindow() {
        if (globalState.configRouting === undefined || state.input === undefined) {
            return <div>Please select a routing to view/delete or New to create a new routing</div>;
        } else {
            return (
                <div>
                    <div className="ui message">
                        <div className="header">Status:</div>
                        {renderStatus()}
                    </div>
                    {renderDeleteButton()}
                    <div className="spacing">_id: {globalState.configRouting}</div>
                    <form id="frm1">
                        <div className="spacing">
                            Routing Name:
                            <input
                                className="input"
                                type="text"
                                name="name"
                                value={state.input.name}
                                onChange={(e) => {
                                    var routing = { ...state.input };
                                    routing.name = e.target.value;
                                    setState({ ...state, input: routing });
                                }}
                            />
                        </div>
                        {state.input.steps.map((step, stepIndex) => (
                            <div className="poContent" key={stepIndex}>
                                <div className="spacing bold">Step Index: {stepIndex}</div>
                                {allowedStepUpdates.map((field) => {
                                    if (field === 'workerGroup') {
                                        return (
                                            <div className="spacing" key={field}>
                                                Worker Group:
                                                <div style={{ marginLeft: '20px' }}>
                                                    {step.workerGroup.map((group, groupIndex) => (
                                                        <div key={groupIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                            <input
                                                                className="input"
                                                                type="text"
                                                                value={group || ''}
                                                                onChange={(e) => {
                                                                    var routing = { ...state.input };
                                                                    routing.steps[stepIndex].workerGroup[groupIndex] = e.target.value;
                                                                    setState({ ...state, input: routing });
                                                                }}
                                                                style={{ width: '200px' }}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => removeArrayItem(stepIndex, 'workerGroup', groupIndex)}
                                                                className='button'
                                                                style={{ marginLeft: '10px', color: 'red' }}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button
                                                        type="button"
                                                        onClick={() => addArrayItem(stepIndex, 'workerGroup')}
                                                        className='button'
                                                        style={{ marginTop: '5px' }}
                                                    >
                                                        Add Worker Group
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    } else if (field === 'tools' || field === 'program') {
                                        return (
                                            <div className="spacing" key={field}>
                                                {field.charAt(0).toUpperCase() + field.slice(1)}:
                                                <div style={{ marginLeft: '20px' }}>
                                                    {step[field].map((item, itemIndex) => (
                                                        <div key={itemIndex} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                                                            <div>
                                                                Type:
                                                                <input
                                                                    className="input"
                                                                    type="text"
                                                                    value={item.type || ''}
                                                                    onChange={(e) => {
                                                                        var routing = { ...state.input };
                                                                        routing.steps[stepIndex][field][itemIndex].type = e.target.value;
                                                                        setState({ ...state, input: routing });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                Params:
                                                                <div style={{ marginLeft: '20px' }}>
                                                                    {item.params.map((param, paramIndex) => (
                                                                        <div
                                                                            key={paramIndex}
                                                                            style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
                                                                        >
                                                                            <input
                                                                                className="input"
                                                                                type="text"
                                                                                value={param.key}
                                                                                onChange={(e) => {
                                                                                    const newKey = e.target.value;
                                                                                    updateParamKey(stepIndex, field, itemIndex, paramIndex, newKey);
                                                                                }}
                                                                                style={{ width: '100px', marginRight: '10px' }}
                                                                            />
                                                                            :
                                                                            <input
                                                                                className="input"
                                                                                type={typeof param.value === 'number' ? 'number' : 'text'}
                                                                                value={param.value ?? ''}
                                                                                onChange={(e) => {
                                                                                    const newValue = e.target.value;
                                                                                    updateParamValue(stepIndex, field, itemIndex, paramIndex, newValue);
                                                                                }}
                                                                                style={{ width: '150px', marginLeft: '10px' }}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => removeParam(stepIndex, field, itemIndex, paramIndex)}
                                                                                className='button'
                                                                                style={{ marginLeft: '10px', color: 'red' }}
                                                                            >
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => addParam(stepIndex, field, itemIndex)}
                                                                        className='button'
                                                                        style={{ marginTop: '5px' }}
                                                                    >
                                                                        Add Param
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={() => removeArrayItem(stepIndex, field, itemIndex)}
                                                                className='button'
                                                                style={{ marginTop: '5px', color: 'red' }}
                                                            >
                                                                Remove {field === 'tools' ? 'Tool' : 'Program'}
                                                            </button>
                                                        </div>
                                                    ))}
                                                    <button
                                                        type="button"
                                                        onClick={() => addArrayItem(stepIndex, field)}
                                                        className='button'
                                                        style={{ marginTop: '5px' }}
                                                    >
                                                        Add {field === 'tools' ? 'Tool' : 'Program'}
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="spacing" key={field}>
                                                {field}:
                                                <input
                                                    className="input"
                                                    type={typeof step[field] === 'number' ? 'number' : 'text'}
                                                    name={`${field}.${stepIndex}`}
                                                    value={step[field]}
                                                    onChange={(e) => {
                                                        var routing = { ...state.input };
                                                        routing.steps[stepIndex][field] = typeof step[field] === 'number' ? Number(e.target.value) : e.target.value;
                                                        setState({ ...state, input: routing });
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        ))}
                    </form>
                </div>
            );
        }
    }

    return <div>{renderRoutings()}</div>;
};

export default RoutingConfig;