import React, { useEffect, useState } from 'react';
import '../style.css';
import { partNumbers, routings, customers } from '../dbNames';
import useGlobal from '../../store';
import server from '../../api/server'

const PartNumberConfig = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure part number',
        input: ['', '']
    });
    const allowedUpdates = ['name']

    useEffect(() => {
        globalActions.getData(partNumbers, '{}', globalState.token);
        globalActions.getData(routings, '{}', globalState.token);
        globalActions.getData(customers, '{}', globalState.token);
        globalActions.configureAction('configPartNumber', undefined)
        globalActions.configureAction('configRouting', undefined)
        globalActions.configureAction('configCustomer', undefined)
    }, []);

    const selectPartNumber = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configPartNumber', event.target.id);
            var pn = '';
            for (var i = 0; i < globalState.partNumbers.length; i++) {
                if (globalState.partNumbers[i]._id === event.target.id) {
                    pn = globalState.partNumbers[i];
                    break;
                }
            }
            var array = state.input
            allowedUpdates.map((field, index) => {
                array[index] = pn[field]
            })
            globalActions.configureAction('configRouting', pn['routingID'])
            globalActions.configureAction('configCustomer', pn['customerID'])
            setState({ ...state, status: 'Ready to configure part number' ,input: array })
        }
    }

    const selectRouting = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configRouting', event.target.id)
        }
    }

    const selectCustomer = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configCustomer', event.target.id)
        }
    }

    const newPartNumber = (event) => {
        if (event !== undefined) {
            setState({ ...state, input: ['**name**', 0] })
            globalActions.configureAction('configPartNumber', event.target.id)
            globalActions.configureAction('configRouting', undefined)
        }
    }

    function renderStatus() {
        if (globalState.partNumbers === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            if (globalState.configRouting === undefined || globalState.configCustomer === undefined) {
                setState({ ...state, status: 'YOU MUST SELECT A CUSTOMER AND A ROUTING TO ASSOCIATE' })
            } else {
                var z = document.getElementById("frm1")
                var updateObject = {}
                for (var i = 0; i < z.length; i++) {
                    if (z.elements[i].value.indexOf(',') === -1) {
                        updateObject[z.elements[i].name] = z.elements[i].value
                    } else {
                        updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                    }
                }
                updateObject['customerID'] = globalState.configCustomer
                updateObject['routingID'] = globalState.configRouting
                const config = {
                    headers: { Authorization: `Bearer ${globalState.token}` }
                };
                if (globalState.configPartNumber === 'new') {
                    server.post(`/partNumbers`, updateObject, config)
                        .then((response) => {
                            if (response.status === 201) {
                                setState({ ...state, status: 'Part number successfully created' });
                                globalActions.getData(partNumbers, '{}', globalState.token);
                                globalActions.configureAction('configPartNumber', response.data._id)
                            } else {
                                setState({ ...state, status: `Their was an error in part number update, check data entered` })
                            }

                        }).catch((error) => {
                            var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                            var message = `Problem saving part number error code: ${errorCode}`
                            setState({ ...state, status: message });
                        });
                } else {
                    server.patch(`/partNumbers/${globalState.configPartNumber}`, updateObject,config)
                        .then((response) => {
                            if (response.status === 200) {
                                setState({ ...state, status: 'Part numbers successfully updated' });
                                globalActions.getData(partNumbers, '{}', globalState.token);
                            } else {
                                setState({ ...state, status: `Their was an error in part number update, check data entered` })
                            }

                        }).catch((error) => {
                            var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                            var message = `Problem saving part number error code: ${errorCode}`
                            setState({ ...state, status: message });
                        });
                }
            }
        }
    }

    function deletePartNumber(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/partNumbers/${globalState.configPartNumber}`,config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Part number successfully deleted' });
                            globalActions.getData(partNumbers, '{}', globalState.token);
                            globalActions.configureAction('configPartNumber', undefined)
                        } else {
                            setState({ ...state, status: `Their was an error deleting the part number` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem deleting part number error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function renderDeleteButton() {
        if (globalState.configPartNumber !== 'new') {
            return (
                <div className="button" id="delete" onClick={deletePartNumber}>
                    DELETE
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderPartNumbers() {
        if (globalState.partNumbers === undefined) {
            return (<div>Loading....</div>)
        } else {

            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Part Number Configuration
                            <div className="button" id="new" onClick={newPartNumber}>
                                NEW
                            </div>
                        </h2>

                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.partNumbers.map(rt => {
                                var selected = "poContent"
                                if (rt._id === globalState.configPartNumber) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={rt._id} onClick={selectPartNumber} >
                                        <div className="header" id={rt._id}>
                                            <i className="industry icon" id={rt._id}></i>
                                            Name: {rt.name}
                                        </div>
                                        <div className="header" id={rt._id}>
                                            <i className="qrcode icon" id={rt._id}></i>
                                            ID: {rt._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderEditWindow() {
        if (globalState.configPartNumber === undefined) {
            return (<div>Please select a part number to configure or New to create a new part number</div>)
        } else {
            var pn = {}
            for (var i = 0; i < globalState.partNumbers.length; i++) {
                if (globalState.partNumbers[i]._id === globalState.configPartNumber) {
                    pn = globalState.partNumbers[i];
                    break;
                }
            }
            return (
                <div>
                    <div className="ui message">
                        <div className="header">
                            Status:
                        </div>
                        {renderStatus()}
                    </div>
                    <div className="grid">
                        <div className="quality1">
                            <div className="button" id="save" onClick={submitChanges}>
                                SAVE
                            </div>
                            {renderDeleteButton()}
                            <div className="spacing">_id: {globalState.configPartNumber}</div>
                            <form id="frm1">
                                {allowedUpdates.map((field, index) => {
                                    var value = undefined
                                    var type = typeof state.input[index]
                                    if (field === 'routingID') {

                                    } else {
                                        return (
                                            <div className="spacing">
                                                {field}:
                                                    <input
                                                        className="input medium"
                                                        type={type}
                                                        name={field}
                                                        value={state.input[index]}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = e.target.value
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />                                        
                                            </div>
                                        )
                                    }
                                })}
                            </form>                            
                        </div>
                        <div className="quality2">
                            Customer:
                            {globalState.customers.map(cs => {
                                var selected = "poContent"
                                if (cs._id === globalState.configCustomer) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={cs._id} onClick={selectCustomer} >
                                        <div className="header" id={cs._id}>
                                            <i className="industry icon" id={cs._id}></i>
                                            Name: {cs.name}
                                        </div>
                                        <div className="header" id={cs._id}>
                                            <i className="qrcode icon" id={cs._id}></i>
                                            ID: {cs._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="quality3">
                            Associated routing:
                            {globalState.routings.map(rt => {
                                var selected = "poContent"
                                if (rt._id === globalState.configRouting) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={rt._id} onClick={selectRouting} >
                                        <div className="header" id={rt._id}>
                                            <i className="industry icon" id={rt._id}></i>
                                            Name: {rt.name}
                                        </div>
                                        <div className="header" id={rt._id}>
                                            <i className="qrcode icon" id={rt._id}></i>
                                            ID: {rt._id}
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>

            )
        }
    }


    return (
        < div >
            {renderPartNumbers()}
        </div >
    )

}

export default PartNumberConfig;