import React, { useEffect } from 'react';
import useGlobal from "../../store";
import server from '../../api/server';
import { activeManufacturing } from '../../components/dbNames';
import { REJECT } from '../../components/statusTypes'
import '../../components/style.css';

const ActiveManufacturingComponent = props => {
    const [globalState, globalActions] = useGlobal();
    var id = parseInt(props.id);

    useEffect(() => {
        globalActions.getData('configActiveManufacturing', '{}', globalState.token);
    }, []);

    function deleteActiveManufacturing(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to cancel and remove job?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.post('/removePO', { poNum: event.target.id, status: REJECT }, config)
                    .then((response) => {
                        if (response.status === 200) {
                            globalActions.configureAction('configActiveManufacturing', undefined)
                        } else {
                            //setState({ ...state, status: `Their was an error deleting the PO` })                            
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    });
            }
        }
    }

    function renderActiveManufacturing() {
        if (globalState.configActiveManufacturing !== undefined) {
            return (
                <div className="editWindow1">
                    <div className="ui link list p11">
                        <div className="item">
                            <button className="button" id={globalState.activeManufacturing[id].poNum} onClick={deleteActiveManufacturing}>Remove</button>
                        </div>
                    </div>
                    <div className="grid">
                        <div >
                            <div>
                                PO #: {globalState.activeManufacturing[id].poNum}
                            </div>
                            <div>
                                Serial #: {globalState.activeManufacturing[id].partSerialNum}
                            </div>
                            <div>
                                Status: {globalState.activeManufacturing[id].status}
                            </div>
                            <div>
                                Routing Name: {globalState.activeManufacturing[id].routing.name}
                            </div>
                            <div>
                                publicKey: {globalState.activeManufacturing[id].publicKey}
                            </div>
                            <div>
                                totalWorkstationCost: {globalState.activeManufacturing[id].totalWorkstationCost}
                            </div>
                            <div>
                                actualWorkstationCost: {globalState.activeManufacturing[id].actualWorkstationCost}
                            </div>
                            <div>
                                totalDeliveryCost: {globalState.activeManufacturing[id].totalDeliveryCost}
                            </div>
                            <div>
                                actualWorkerCost: {globalState.activeManufacturing[id].actualWorkerCost}
                            </div>
                            <div>
                                activationTransaction: {globalState.activeManufacturing[id].activationTransaction}
                            </div>
                            <div>
                                networkTransaction: {globalState.activeManufacturing[id].networkTransaction}
                            </div>
                            <div>
                                userRefundTransaction: {globalState.activeManufacturing[id].userRefundTransaction}
                            </div>
                            <h5>Step:</h5>
                            <div className="ui bulleted list">
                                {globalState.activeManufacturing[id].routing.steps.map((step, index) => {
                                    var dateStart = 0;
                                    var dateEnd = 0;
                                    if (step.kpv.dateTimeStart !== 0) {
                                        dateStart = new Date(step.kpv.dateTimeStart);
                                        dateEnd = new Date(step.kpv.dateTimeEnd);
                                    } else {
                                        dateStart = 0;
                                        dateEnd = 0;
                                    }
                                    return (
                                        <div className="item">
                                            <div>Step # (start is index 0): {index}</div>
                                            <div>Step Name: {step.name}</div>
                                            <div>Step Status: {step.status}</div>
                                            <div>Workstation Group: {step.workstationGroup}</div>
                                            
                                            <div>Start time: {dateStart.toString()}</div>
                                            <div>End time: {dateEnd.toString()}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            globalActions.getData(activeManufacturing, '{}', globalState.token);
            return <div>Loading....</div>
        }
    }

    return (
        <div>
            {renderActiveManufacturing()}
        </div>
    );
}

export default ActiveManufacturingComponent;